export const getRoomsDetail = (
  data,
  periods,
  dmcorganization,
  toorganization,
) => {
  const rooms = [];
  data?.data.forEach(contract => {
    contract.rooms.forEach(room => {
      let roomInArray = rooms.find(elem => elem.id === room.id);
      if (!roomInArray) {
        roomInArray = {
          id: room.id,
          name: room.name,
          periods: [],
          enabled: false,
        };
        rooms.push(roomInArray);
      }
      const periodsWithAllotment = room.periods;
      periodsWithAllotment.forEach(period => {
        let periodInArray = roomInArray.periods.find(
          elem => elem.id === period.id
        );
        if (!periodInArray) {
          periodInArray = {
            id: period.id,
            startAt: period.startAt,
            endAt: period.endAt,
            freeSale: period.freeSale,
            totalAllotmentsAvailable: period.totalAllotmentsAvailable,
            toElement: {
              isPeriodAllowedForBooking: period.periodOrganizations
                .find(
                  item => item?.organization?.id === toorganization?.id
                )?.isPeriodAllowedForBooking,
              totalAllotmentsAvailableForDistribution:
                period.totalAllotmentsAvailable -
                period.totalSharedAllotmentsAvailable -
                period.periodOrganizations
                  .filter(
                    elem =>
                      elem.organization.id !== dmcorganization?.id &&
                      elem.organization.id !== toorganization?.id
                  )
                  .reduce((acc, value) => acc + value.allotmentCount, 0),
            },
            allAllocations: period.periodOrganizations.filter(
              elem => elem.organization.id !== toorganization?.id
            ),
          };
          roomInArray.periods.push(periodInArray);
        }
        const periodInHotel = periods.find(elem => elem.id === period.id);
        if (periodInHotel) {
          periodInArray.toElement = {
            ...periodInArray.toElement,
            allotmentCount: periodInHotel.allotmentCount,
            freeSale: periodInHotel.freeSale,
            isMarginPercent: periodInHotel?.isMarginPercent,
            margin: periodInHotel?.margin,
          };
        } else {
          const periodOrgaTo = period.periodOrganizations.find(
            elem => elem.organization.id === toorganization?.id
          );
          if (!periodOrgaTo) {
            periodInArray.toElement = {
              ...periodInArray.toElement,
              allotmentCount: 0,
              freeSale: false,
              isPeriodAllowedForBooking: false,
            };
          } else {
            periodInArray.toElement = {
              ...periodInArray.toElement,
              allotmentCount: periodOrgaTo.allotmentCount,
              freeSale: periodOrgaTo.freeSale,
              isPeriodAllowedForBooking: periodOrgaTo.isPeriodAllowedForBooking,
              isMarginPercent: periodOrgaTo?.isMarginPercent,
              margin: periodOrgaTo?.margin,
            };
          }
        }
      });
      roomInArray.enabled =
        roomInArray.periods.findIndex(elem => {
          return elem.toElement.isPeriodAllowedForBooking;
        }) !== -1;
    });
  });
  return rooms;
};
